<template>
  <div
    class="widgetContainer widgetContainer--scrollable  full-width transactionDetails"
    v-if="getSelectedTransaction">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('transaction_details') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    
    <div class="widgetContainer__body">
      <div class="listWithAmount">
        <ul class="summary-box">
          <li class="cursor-auto">
            <div class="details trans-details">
              <p
                :class="
                  'amount ' +
                    (getSelectedTransaction.status == 'pending'
                      ? 'pending'
                      : getSelectedTransaction.txnType === 'debit'
                        ? 'minus'
                        : 'plus')
                ">
                {{ getSelectedTransaction.amount | transactionAmount }}
              </p>

              <p class="label">
                {{ getSelectedTransaction.title }}
              </p>
              <p
                class="value"
                v-if="getSelectedTransaction.status == 'pending'">
                {{ $t('RCD_pending') }}
              </p>
              <p class="value" v-else>
                {{
                  getSelectedTransaction.txnDate
                    | date("MMM d, yyyy 'at' h:mm aa")
                }}
              </p>
            </div>
            <el-tag
              :class="'el-tag--blue transaction-type type-' + transferTypeTitle">
              {{ transferTypeTitle }}
            </el-tag>
          </li>
        </ul>
      </div>

      <div class="listSimple transactionDetails__other-details">
        <h4 class="header-2">
          {{ $t('other_details') }}
        </h4>
        <ul>
          <li v-if="data.source && false">
            <p class="label">
              {{ $t('source') }}
            </p>
            <p class="value">
              {{ data.source }}
            </p>
          </li>
          <li v-if="data.paidTo">
            <p class="label">
              {{ $t('merchant') }}
            </p>
            <p class="value">
              {{ data.paidTo }}
            </p>
          </li>
          <li v-if="data.transactionId && false">
            <p class="label">
              {{ $t('transactionId') }}
            </p>
            <p class="value">
              {{ data.transactionId }}
            </p>
          </li>
          <li v-if="data.description">
            <p class="label">
              {{ $t('description') }}
            </p>
            <p class="value">
              {{ data.description }}
            </p>
          </li>
        </ul>
      </div>

      <div class="listWithActions transactionDetails__actions">
        <h4 class="header-2">
          {{ $t('actions') }}
        </h4>
        <ul>
          <li @click="getData(true)">
            {{ $t('viewPDF') }}
          </li>
          <li @click="openHelp">
            {{ $t('reportProblem') }}
          </li>
        </ul>
      </div>

      <div
        class="listSimple"
        v-if="
          getSelectedTransaction.transferType === 'card' &&
            getSelectedTransaction.card &&
            getSelectedTransaction.card.merchant
        ">
        <h4 class="header-2">
          {{ $t('location') }}
        </h4>
        <ul class="card-with-map">
          <div class="map">
            <GmapMap
              v-if="lat && lng"
              :center="{ lat, lng }"
              :zoom="7"
              map-type-id="terrain"
              style="width: 100%; height: 200px">
              <GmapMarker
                :position="{ lat, lng }"
                :clickable="true"
                :draggable="false" />
            </GmapMap>
          </div>
          <li>
            <p class="label">
              {{ $t('address') }}
            </p>
            <p class="value">
              {{ address }}
            </p>
          </li>
          <li>
            <p class="label">
              {{ $t('contact_country_title') }}
            </p>
            <p class="value">
              {{ getSelectedTransaction.card.merchant.merchantCountry }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import { transferType } from '../enums/index';
export default {
  data() {
    return {
      lat: null,
      lng: null
    };
  },
  created() {
    if (this.getSelectedTransaction == null) {
      this.$router.push('/transaction/list');
    } else {
      setTimeout(() => {
        this.getData();
      }, 0);
    }
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('transaction', ['getSelectedTransaction']),
    google: gmapApi,
    data() {
      let source, paidTo, receivedFrom, description, transactionId, location;
      /** @type {import('@m/transaction/store/state').transactionState['selectedTransaction']} */
      const transaction = this.getSelectedTransaction;
      switch (
        transaction.transferType //intrabank
      ) {
      case 'intrabank':
        if (
          transaction.txnType === 'debit' ||
            transaction.txnType === 'credit'
        ) {
          source = transaction.accountId;
          paidTo = transaction.intrabank.contactName;
          description = transaction.description;
          transactionId = transaction.id;
        }
        break;
      case 'ach':
        if (transaction.txnType === 'debit') {
          source = transaction.accountId;
          paidTo = transaction.ach.contactName;
          description = transaction.description;
          transactionId = transaction.id;
        } else if (transaction.txnType === 'credit') {
          //
        }
        break;
      case 'card':
        source = transaction.card.label;
        paidTo = transaction.card.merchant.merchantName;
        description = transaction.description;
        transactionId = transaction.id;
        break;
      default:
        description = transaction.description;
        break;
      }
      return {
        source,
        paidTo,
        receivedFrom,
        description,
        transactionId,
        location
      };
    },
    address() {
      const address = this.getSelectedTransaction.card.merchant;
      return (
        address.merchantName +
        ',' +
        address.merchantCity +
        ',' +
        address.merchantState
      );
    },
    transferTypeTitle() {
      if (this.getSelectedTransaction) {
        return transferType['titles'][this.getSelectedTransaction.transferType];
      } else {
        return '';
      }
    }
  },
  methods: {
    ...mapActions('transaction', ['fetchTransaction']),
    getLatLong() {
      this.$gmapApiPromiseLazy().then(() => {
        if (
          this.google &&
          this.getSelectedTransaction.transferType === 'card' &&
          this.getSelectedTransaction.card &&
          this.getSelectedTransaction.card.merchant
        ) {
          const geocoder = new this.google.maps.Geocoder();
          const address = this.getSelectedTransaction.card.merchant;
          const addressString =
            address.merchantName +
            ',' +
            address.merchantCity +
            ',' +
            address.merchantState +
            ',' +
            address.merchantCountry;

          geocoder.geocode(
            {
              address: addressString
            },
            (results, status) => {
              if (status == this.google.maps.GeocoderStatus.OK) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                this.lat = latitude;
                this.lng = longitude;
              }
            }
          );
        }
      });
    },
    getData(isDownload = false) {
      const loader = this.showLoader();
      this.fetchTransaction({
        accountId: this.getSelectedAccount.id,
        transactionId: this.getSelectedTransaction.id,
        format: isDownload ? 'pdf' : 'json'
      })
        .then((res) => {
          if (res && isDownload) {
            const url = window.URL.createObjectURL(res);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${this.getSelectedTransaction.id}-statement.pdf`;
            a.click();
            a.remove();
          }
          if (isDownload === false) {
            this.getLatLong();
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
          this.isLoading = false;
        });
    },
    goToHome() {
      this.goToPreviousScreen();
    },
  }
};
</script>

<style lang="scss">
.transactionDetails {
  .widgetContainer__body {
    padding-top: 16px;
  }
  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .export-btn {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
      color: #767676;
      cursor: pointer;
    }
  }
  
  .summary-box {
    padding: 23px 16px 23px 0px;
    border-radius: var(--radius);
    border: 1px solid #eeeeee;

    .amount {
      margin-bottom: 24px;
      font-size: 16px;
    }

    .label {
      color: var(--branding);
    }

    .el-tag {
      align-self: flex-start;
    }

    .details.trans-details {
      max-width: 63%;
  
      .label {
        padding-right: 5px;
        white-space: inherit;
      }
    }
  }
  .listSimple .card {
    border-radius: 4px;
  }

  &__actions {
    ul {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      padding: 0;
      background-color: transparent;
      cursor: default;

      li {
        width: 46%;
        background-color: white;
        height: 48px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: var(--radius);
        border: 1px solid #eeeeee;

        &:first-of-type {
          border: 1px solid #eeeeee;
        }

        &:hover {
          background-color: var(--light-grey-3);
        }
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    .back {
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon-arrow-left {
        font-size: 18px;
      }

      p {
        color: #3c3c43;
        font-size: 17px;
        padding-left: 6px;
      }
    }

    .export {
      color: var(--branding);
      font-size: 17px;
      cursor: pointer;
    }
  }

  .listSimple ul li .value {
    font-weight: 500;
    max-width: 60%;
  }

  &__other-details {
    ul {
      border-radius: var(--radius);
      border: 1px solid #eeeeee;
      
      li {
        justify-content: flex-start;
        .label {
          width: 50%;
          min-width: 50%;
        }
      }
    }
  }

  .card-with-map {
    padding: 10px !important;
    .action-box {
      padding: 0px 18px 24px;
      border-bottom: 1px solid #eeeeee;
      &:last-child {
        border-bottom: unset;
        padding-bottom: 6px;
      }
      .label {
        width: 100%;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #767676;
        margin-bottom: 6px;
      }
      .value {
        max-width: 100% !important;
      }
    }
  }
  .map {
    margin-bottom: 20px;
  }
}
</style>
